import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PageTemplate from 'templates/PageTemplate';
import Card from 'components/Card';

import Tesselate from 'images/tesselate-pattern.jpg';

class Blog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postsPerPage: 3,
      visiblePosts: 4,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  getFeatureCard() {
    const { data } = this.props;

    if (data.featured && data.featured.edges && data.featured.edges.length > 0) {
      if (data.featured.edges[0].node) {
        const {
          excerpt,
          fields,
          frontmatter,
        } = data.featured.edges[0].node;
        const { date, title } = frontmatter;
        const { postPath } = fields;

        return (
          <Card
            key={postPath}
            date={date}
            featured
            summary={excerpt}
            title={title}
            url={postPath}
          />
        );
      }
    }

    return null;
  }

  getPosts() {
    const { data } = this.props;
    const { edges: posts } = data.allPosts;
    const { visiblePosts } = this.state;

    return posts
      .filter((post) => post.node.frontmatter.title.length > 0)
      .slice(0, visiblePosts)
      .map(({ node: post }) => (
        <Card
          key={post.fields.postPath}
          date={post.frontmatter.date}
          summary={post.excerpt}
          title={post.frontmatter.title}
          url={post.fields.postPath}
        />
      ));
  }

  handleClick() {
    const { data } = this.props;
    const { edges: posts } = data.allPosts;
    const { postsPerPage, visiblePosts } = this.state;

    if (posts.length <= visiblePosts + postsPerPage) {
      this.setState(() => ({
        visiblePosts: posts.length,
      }));

      return;
    }

    this.setState(() => ({
      visiblePosts: visiblePosts + postsPerPage,
    }));
  }

  render() {
    const { data } = this.props;
    const { edges: posts } = data.allPosts;
    const { visiblePosts } = this.state;
    const loadMoreButton = posts.length > visiblePosts ? (
      <button className="btn mt4" type="button" onClick={this.handleClick}>
        Load More
      </button>
    ) : null;

    return (
      <PageTemplate className="insights" metaDescription="OutVox’s insight on leading industry topics that matter.">
        <Helmet title="Insights" />

        <header>
          <div className="insights--title">
            <h1>Insights</h1>
            <h2>OutVox&rsquo;s insight on leading industry topics that matter.</h2>
          </div>

          <img src={Tesselate} alt="Architectural tesselation pattern" width="700px" height="auto" className="insights--image shadow" />
        </header>

        <div className="container mb5">
          <div className="card--list">
            {this.getFeatureCard()}
            {this.getPosts()}
          </div>

          <div className="tc pb4">
            {loadMoreButton}
          </div>
        </div>
      </PageTemplate>
    );
  }
}

Blog.propTypes = {
  data: PropTypes.shape({
    allPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape(),
        }),
      ),
    }),
    featured: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape(),
        }),
      ),
    }),
    pathname: PropTypes.string,
  }).isRequired,
};

export const pageQuery = graphql`
  query {
    allPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          example: { ne: true }
          featured: { ne: true }
        }
        fileAbsolutePath: {regex: "/(..\/posts)/.*\\.md$/"}
      }
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 120)
          id
          fields {
            postPath
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }

    featured: allMarkdownRemark(
      filter: {
        frontmatter: {
          example: { ne: true }
          featured: { ne: false }
        }
        fileAbsolutePath: {regex: "/(..\/posts)/.*\\.md$/"}
      }
      sort: {
        order: DESC,
        fields: [frontmatter___date]
      }
      limit: 1
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            postPath
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;

export default Blog;
